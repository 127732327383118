<template>
  <div class="setBox">
    <div class="boxTop">
      <div class="topLeft" @click="goBack()"></div>
      <div class="topCon">查找</div>
      <div class="topRight"></div>
    </div>
      <div class="container" v-for="item in friends" :key="item.id">
        <div class="content" @click="goTrendIndex(item.id)">
          <div class="hear">
            <img :src="item.headImg">
          </div>
          <div class="nameSchool">
            <div>{{ item.nickName }}</div>
            <div>{{ item.schoolName }}</div>
          </div>
        </div>
      </div>
<!--    <div class="container">-->
<!--      <div class="content" @click="goTrendIndex()">-->
<!--        <div class="hear"></div>-->
<!--        <div class="nameSchool">-->
<!--          <div>潇洒先生</div>-->
<!--          <div>美国帝国理工大学</div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
import {searchFriend} from "@/api/user";

export default {
  data() {
    return {
      friends: '',
      search: {
        pageNum: 1,
        pageSize: 10,
        mobile: '',
        provinceId: '',
        cityId: '',
        countyId: '',
      }
    };
  },
  mounted() {
    this.search.mobile = this.$route.query.mobile;
    // console.log(this.search.mobile)
    this.search.provinceId = this.$route.query.provinceId;
    this.search.cityId = this.$route.query.cityId;
    this.search.countyId = this.$route.query.countyId;
    this.goSeekResult();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goTrendIndex(id){
        this.$router.push({path:'/TrendIndex', query:{userId: id}})
    },
    goSeekResult() {
      console.log(this.search)
      if (this.search.mobile == '' && this.search.provinceId == '' &&
          this.search.cityId == '' && this.search.countyId == '') {
        alert("查询条件不能为空！")
        return ;
      }
      searchFriend(this.search).then(res => {
        // console.log(res, "好友申请查询结果");
        if (res.data.code === 0) {
           this.friends = res.data.data.items;
           this.search.pageNum = res.data.data.pageNum;
           this.search.pageSize = res.data.data.pageSize;
        }
      });
    },
  },
};
</script>

<style scoped>
.boxTop {
  width: 750px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 22px;
}
.topLeft {
  width: 48px;
  height: 48px;
  background-image: url("../../image/back.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.topCon {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
}
.topRight {
  width: 48px;
  height: 48px;
  margin-right: 32px;
}
.container {
  margin-top: 56px;
}
.content {
  display: flex;
  margin-left: 32px;
  margin-bottom: 40px;
}
.hear {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  //background: red;
  overflow: hidden;
}
.hear img {
  height: 100%;
}
.nameSchool {
  margin-left: 20px;
}
.nameSchool div:nth-child(1) {
  font-size: 28px;
  font-weight: 600;
  color: #0b1526;
}
.nameSchool div:nth-child(2) {
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  margin-top: 6px;
}
</style>
